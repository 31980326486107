import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {IonicModule} from '@ionic/angular';
import {SecondaryRoutingModule} from './shared-routing.module';
import {RightMenuComponent} from './right-menu/right-menu.component';
import {AccountOverviewComponent} from './MarketRunnerComponents/account-overview/account-overview.component';
import {RulesComponent} from './MarketRunnerComponents/rules/rules.component';
import {ResultPopupComponent} from './MarketRunnerComponents/result-popup/result-popup.component';
import {ResultPopupComponentNew} from './MarketRunnerComponents/result-popup-new/result-popup-new.component';
import {MinMaxComponent} from './min-max/min-max.component';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        SecondaryRoutingModule
    ],
    declarations: [
        RightMenuComponent, AccountOverviewComponent, ResultPopupComponent, ResultPopupComponentNew, MinMaxComponent
    ],
    exports: [
        RightMenuComponent, AccountOverviewComponent, ResultPopupComponent, ResultPopupComponentNew, MinMaxComponent
    ]
})
export class SharedModule {}
